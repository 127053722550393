import React, {useEffect, useState} from "react";
import { useNavigate, NavLink } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import Modal from '@mui/material/Modal';
import { API_ENDPOINT, COLOR_BRAND } from "../../consts";
import Add from "./ProductGroups/Add";
import Essentials from "./ProductGroups/Essentials";
import Details from "./ProductGroups/Details";
import ProductImages from "./ProductGroups/ProductImages";
import Characteristics from "./ProductGroups/Characteristics";
import Packaging from "./ProductGroups/Packaging";
import Ingredients from "./ProductGroups/Ingredients";
import Certificates from "./ProductGroups/Certificates";

const useStyles = makeStyles((theme) => ({
  container_fillIn: {
    display: "flex",
    width: "100%", 
    paddingTop: 20, 
    paddingBottom: 20, 
    backgroundColor: "#f1f1f1", 
    color: "#000", 
    borderBottom: "1px solid rgba(0,0,0,.2)",
    flexDirection: "row"
  },
  container_fillIn_label_container: {
    display: "flex",
    paddingLeft: 20,
    flex: 1,
    alignItems: "center"
  },
  container_fillIn_button_container: {
    flex: 6
  },
  container_fillIn_button: {
    display: "inline-block",
    padding: 5,
    paddingLeft: 15,
    paddingRight: 15,
    backgroundColor: "#ffffff99",
    border: "1px solid rgba(0,0,0,.2)",
    cursor: "pointer",
    color: COLOR_BRAND,
    fontSize: 23
  }
}));

export function ProductGroup (props) {
  const classes = useStyles();
  const navigate = useNavigate();
  const [label, setLabel] = useState("");
  const [isSet, setIsSet] = useState(false);
  const [currentGroup, setCurrentGroup] = useState(<></>);
  const [id, setId] = useState(props.id);

  useEffect(() => {
    _setLabel();
  }, []);

  const _setLabel = () => {
    var newLabel = "";
    var newProps = {...props.data, "section": props.section};
    switch(props.section) {
      case "essentials":
        newLabel = "Essentials";
        setCurrentGroup(<Essentials id={id} label={newLabel} data={newProps} />);
        setIsSet(true);
        break;
      case "details":
        newLabel = "Details";
        setCurrentGroup(<Details id={id} label={newLabel} data={newProps} />);
        setIsSet(true);
        break;
      case "productImages":
        newLabel = "Product Images";
        setCurrentGroup(<ProductImages id={id} label={newLabel} data={newProps} />);
        setIsSet(true);
        break;
      case "characteristics":
        newLabel = "Characteristics";
        setCurrentGroup(<Characteristics id={id} label={newLabel} data={newProps} />);
        setIsSet(true);
        break;
      case "packaging":
        newLabel = "Packaging";
        setCurrentGroup(<Packaging id={id} label={newLabel} data={newProps} />);
        setIsSet(true);
        break;
      case "ingredients":
        newLabel = "Ingredients";
        setCurrentGroup(<Ingredients id={id} label={newLabel} data={newProps} />);
        setIsSet(true);
        break;
      case "certificates":
        newLabel = "Certificates";
        setCurrentGroup(<Certificates id={id} label={newLabel} data={newProps} />);
        setIsSet(true);
        break;
    }
    setLabel(newLabel);
  };

  if(!isSet) {
    return (
      <Add id={id} label={label} />
    );
  } else {
    return(
      currentGroup
    );
  }
  
  
}