import React, {useEffect, useState} from "react";
import { useNavigate, NavLink } from "react-router-dom";
import { useSelector } from 'react-redux';
import { makeStyles } from "@mui/styles";
import TextField from '@mui/material/TextField';
import { Cancel } from "@mui/icons-material";
import Autocomplete from '@mui/material/Autocomplete';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import axios from "axios";
import Snackbar from '@mui/material/Snackbar';
import { API_ENDPOINT, COLOR_BRAND } from "../../../consts";
import Status from "./Status";

const useStyles = makeStyles((theme) => ({
  container_outer: {
    display: "flex",
    width: "100%",
    backgroundColor: "#fafafa", 
    color: "#000", 
    borderBottom: "1px solid rgba(0,0,0,.2)",
    flexDirection: "column"
  },
  container_outer_save_button: {
    display: "flex",
    width: "96%", 
    padding: "2%",
    justifyContent: "center"
  },
  container_inner: {
    display: "flex",
    width: "96%", 
    padding: "2%",
    paddingBottom: "7%",
    color: "#000", 
    flexDirection: "row"
  },
  column_left: {
    flex: 1
  },
  column_center: {
    display: "flex",
    flex: 4,
    flexDirection: "row"
  }, 
  column_center_half: {
    flex: 1
  },
  column_right: {
    flex: 1,
    flexDirection: "column",
    display: "flex"
  },
  input_string: {
    width: "80%",
    maxWidth: 750,
    marginBottom: 20,
    backgroundColor: "#ffffff"
  },
  horizontalSpacerSmall: {
    width: "100%",
    height: 20
  },
}));


export default function (props) {
  const classes = useStyles();
  const user = useSelector(state => state.user);
  const [isSnackbar, setIsSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState(false);
  const [isReadOnly, setIsReadOnly] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [data, setData] = useState(props.data);
  const [flavours, setFlavours] = useState(props.data.flavours);
  const [flavoursUsed, setFlavoursUsed] = useState([]);
  const [keyflavours, setKeyFlavours] = useState(Date.now());
  const [idFlavour, setIdFlavour] = useState("");
  const [flavourLabel, setFlavourLabel] = useState("");
  const [keywords, setKeywords] = useState(props.data.keywords);
  const [keywordsUsed, setKeywordsUsed] = useState([]);
  const [keyKeywords, setKeyKeywords] = useState(Date.now()+1);
  const [idKeyword, setIdKeyword] = useState("");
  const [keywordLabel, setKeywordLabel] = useState("");

  useEffect(() => {
    filterFlavours(props.data);
    filterKeywords(props.data);
  }, []);

  const filterFlavours = (data) => {
    let flavoursUnused = [];
    let flavoursUsed = [];
    for(let i=0; i<data.flavours.length; i++) {
      if(data.flavours[i].isSaved === 1) {
        flavoursUsed[flavoursUsed.length] = data.flavours[i];
      } else {
        flavoursUnused[flavoursUnused.length] = data.flavours[i];
      }
    }
    setFlavours(flavoursUnused);
    setFlavoursUsed(flavoursUsed);
  };

  const filterKeywords = (data) => {
    let keywordsUnused = [];
    let keywordsUsed = [];
    for(let i=0; i<data.keywords.length; i++) {
      if(data.keywords[i].isSaved === 1) {
        keywordsUsed[keywordsUsed.length] = data.keywords[i];
      } else {
        keywordsUnused[keywordsUnused.length] = data.keywords[i];
      }
    }
    setKeywords(keywordsUnused);
    setKeywordsUsed(keywordsUsed);
  };

  const saveForm = () => {
    setIsSaving(true);
    setIsReadOnly(true);
    axios.post(API_ENDPOINT+"?func=update_product", {
      id_updatedBy: user.id,
      id: props.data.id,
      section: props.data.section,
      flavours: JSON.stringify(flavoursUsed),
      keywords: JSON.stringify(keywordsUsed)
    },{
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    })
    .then(res => {
      if(res.status === 200) {
        var tmpData = data;
        tmpData.characteristics_status = 1;
        setData(tmpData);
        setSnackbarMessage("Product saved successfully");
        setIsSnackbar(true);
      } else {
        setIsReadOnly(false);
      }
      setIsSaving(false);
    });
  };

  const cancelForm = () => {
    //setIsReadOnly(true);
    //filterFlavours(props.data);
    //filterKeywords(props.data);
  };

  const snackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setIsSnackbar(false);
  };

  return(
    <>
      <div className={classes.container_outer}>
        <div className={classes.container_inner}>
          <div className={classes.column_left}>
            <b>{props.label}</b>
          </div>
          <div className={classes.column_center}>
            <div className={classes.column_center_half}>
            <Autocomplete
              key={keyflavours}
              disablePortal
              id="combo-box-demo"
              className={classes.input_string} 
              style={{ marginBottom: 5 }}
              options={flavours}
              readOnly={isReadOnly ? true : false} 
              renderInput={(params) => <TextField {...params} label="Flavours" style={{ backgroundColor: isReadOnly ? "#00000012" : "#fff" }} />}
              onChange={(event, newValue) => {

                var tempData = [...props.data.flavours];
                for(var i = 0; i<tempData.length; i++) {
                  if(tempData[i].id === newValue.id) {
                    tempData[i].isSaved = 1;
                  }
                }
                var newData = props.data;
                newData.flavours = tempData;
                filterFlavours(newData);
                setKeyFlavours(prev => prev + Date.now());
              }}
            />
            <Stack direction="row" spacing={1} className={classes.input_string} style={{ backgroundColor: "transparent", maxWidth: 500 }} >
              {flavoursUsed.map((item, index) => (
                <div>
                  <Chip 
                    label={<Typography style={{ userSelect: 'text' }}>{item.label}</Typography>}
                    onDelete={() => {
                      var tempData = [...props.data.flavours];
                      for(var i = 0; i<tempData.length; i++) {
                        if(tempData[i].id === item.id) {
                          tempData[i].isSaved = 0;
                        }
                      }
                      var newData = props.data;
                      newData.flavours = tempData;
                      filterFlavours(newData);
                      setKeyFlavours(prev => prev + Date.now());
                    }} 
                    deleteIcon={isReadOnly ? <></> : <Cancel />}
                    sx={{ justifyContent: "flex-start" }}
                  />
                </div>
              ))}
            </Stack>
            <div className={classes.horizontalSpacerSmall} />
            <Autocomplete
              key={keyKeywords}
              disablePortal
              id="combo-box-demo"
              className={classes.input_string} 
              style={{ marginBottom: 5 }}
              options={keywords}
              readOnly={isReadOnly ? true : false} 
              renderInput={(params) => <TextField {...params} label="Keywords" style={{ backgroundColor: isReadOnly ? "#00000012" : "#fff" }} />}
              onChange={(event, newValue) => {
                var tempData = [...props.data.keywords];
                for(var i = 0; i<tempData.length; i++) {
                  if(tempData[i].id === newValue.id) {
                    tempData[i].isSaved = 1;
                  }
                }
                var newData = props.data;
                newData.keywords = tempData;
                filterKeywords(newData);
                setKeyKeywords(prev => prev + Date.now());
              }}
            />
            <Stack direction="column" spacing={1} className={classes.input_string} style={{ backgroundColor: "transparent", maxWidth: 500, flexWrap: "wrap" }} >
              {keywordsUsed.map((item, index) => (
                <div>
                  <Chip 
                    label={<Typography style={{ userSelect: 'text' }}>{item.label}</Typography>}
                    onDelete={() => {
                      var tempData = [...props.data.keywords];
                      for(var i = 0; i<tempData.length; i++) {
                        if(tempData[i].id === item.id) {
                          tempData[i].isSaved = 0;
                        }
                      }
                      var newData = props.data;
                      newData.keywords = tempData;
                      filterKeywords(newData);
                      setKeyKeywords(prev => prev + Date.now());
                    }} 
                    deleteIcon={isReadOnly ? <></> : <Cancel />}
                    sx={{ justifyContent: "flex-start" }}
                  />
                </div>
              ))}
            </Stack>
            </div>
          </div>
          <div className={classes.column_right}>
            <Status isSaving={isSaving} isReadOnly={isReadOnly} setIsReadOnly={setIsReadOnly} data={data} section={props.section} />
          </div>
        </div>
        {!isReadOnly ? (
          <div className={classes.container_outer_save_button}>
            <Button style={{ color: COLOR_BRAND, backgroundColor: "#fff", marginRight: 10 }} onClick={cancelForm} >Cancel</Button>
            <Button style={{ color: "#fff", backgroundColor: COLOR_BRAND }} variant="contained" size="medium" onClick={saveForm}>SAVE</Button>
          </div>
        ) : null}
      </div>
      <Snackbar
        open={isSnackbar}
        autoHideDuration={1000}
        onClose={snackbarClose}
        message={snackbarMessage}
        action={<div />}
        key={Date.now()}
      />
    </>
  );
}