import React, {useEffect, useState} from "react";
import { useNavigate, NavLink } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import { DataGrid } from '@mui/x-data-grid';
import axios from "axios";
import Snackbar from '@mui/material/Snackbar';
import TextField from '@mui/material/TextField';
import { Delete, Add } from "@mui/icons-material";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { API_ENDPOINT, COLOR_BRAND } from "../consts";

const useStyles = makeStyles((theme) => ({
  container: {
    flex: 1
  },
  innerContainer: {
    display: "flex",
    width: "100%"
  },
  menuContainer: {
    display: "flex",
    justifyContent: "flex-end",
    paddingRight: 60
  },
  box: {
    width: "100%",
    maxWidth: 800,
    padding: 20,
    backgroundColor: "#fff",
    webkitBoxShadow: "1px 2px 3px #00000033",
    mozBoxShadow: "1px 2px 3px #00000033",
    boxShadow: "1px 2px 3px #00000033",
    borderRadius: 5
  },
  filterString: {
    width: 300,
  },
}));

export function TargetGroups () {
  const [rowsInit, setRowsInit] = useState([]);
  const [rows, setRows] = useState([]);
  const classes = useStyles();

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    axios.get(API_ENDPOINT+'?func=get_target_groups')
    .then(res => {
      let data = res.data;
      if(res.status === 200) {
        setRowsInit(data);
        setRows(data);
      }
    });
  };

  const columns = [
    {
      field: 'name',
      headerName: 'Name',
      width: 150,
      flex: 1,
      editable: false,
      sortable: false,
    }
  ];

  return (
    <>
      <div className={classes.container}>
        <h1>Target Groups</h1>
        <div className={classes.innerContainer}>
          <div className={classes.box}>
            <List>
            {rows.map((item, index) => (
              <ListItem>
                  <ListItemText primary={item.name} />
              </ListItem>
            ))}
            </List>
          </div>
        </div>
      </div>
    </>
  );
  
}