import React, {useEffect, useState} from "react";
import { useSelector } from 'react-redux';
import axios from "axios";
import Snackbar from '@mui/material/Snackbar';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import { makeStyles } from "@mui/styles";
import { ThumbUp, Edit } from "@mui/icons-material";
import { Dots } from "react-activity";
import { API_ENDPOINT, COLOR_BRAND } from "../../../consts";

const useStyles = makeStyles((theme) => ({
  container_fillIn: {
    display: "flex",
    width: "100%", 
    paddingTop: 20, 
    paddingBottom: 20, 
    backgroundColor: "#f1f1f1", 
    color: "#000", 
    borderBottom: "1px solid rgba(0,0,0,.2)",
    flexDirection: "row"
  },
  container_fillIn_label_container: {
    display: "flex",
    paddingLeft: 25,
    flex: 1,
    alignItems: "center"
  },
  container_fillIn_button_container: {
    flex: 6
  },
  container_fillIn_button: {
    display: "inline-block",
    padding: 5,
    paddingLeft: 15,
    paddingRight: 15,
    backgroundColor: "#ffffff99",
    border: "1px solid rgba(0,0,0,.2)",
    cursor: "pointer",
    color: COLOR_BRAND,
    fontSize: 23
  }
}));


export default function (props) {
  const classes = useStyles();
  const user = useSelector(state => state.user);
  const [isSnackbar, setIsSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState(false);
  const [data, setData] = useState(props.data);
  const [section, setSection] = useState(props.section);
  const [isSaving, setIsSaving] = useState(props.isSaving);
  const [viewModal, setViewModal] = useState(false);
  const [approvedDate, setApprovedDate] = useState("");
  const [approvedByName, setApprovedByName] = useState("");

  useEffect(() => {
    console.info(data);
    setApprovedDate(data[data.section+"_updated"]);
    setApprovedByName(data[data.section+"_approvedBy"]);
  }, []);

  useEffect(() =>{
    setIsSaving(props.isSaving);
  },[props.isSaving]);

  const setReadOnly = () => {
    props.setIsReadOnly(props.isReadOnly ? false : true);
  }

  const setApproveModal = () => {
    setViewModal(true);
  };

  const approve = () => {
    axios.post(API_ENDPOINT+"?func=approve_product_group", {
      id_updatedBy: user.id,
      id: props.data.id,
      section: props.data.section
    },{
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    })
    .then(res => {
      if(res.status === 200) {
        var tempData = data;
        tempData[data.section+"_status"] = 2;
        setData(tempData);
        setApprovedByName(user.name);
        const formattedDate = new Date(Date.now()).toLocaleDateString('en-US', {
          month: 'long',
          day: 'numeric',
          year: 'numeric'
        });
        setApprovedDate(formattedDate);
        setSnackbarMessage("Content approved successfully");
        setIsSnackbar(true);
      }
      setViewModal(false);
    });
  };

  const snackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setIsSnackbar(false);
  };

  return(
    <>
      {isSaving ? (
        <Dots />
      ) : (
        <div style={{ fontSize: 12 }}>
          {props.isReadOnly && (
            <>
              {data[data.section+"_status"] === 0 && (
                <>
                  
                </>
              )}
              {data[data.section+"_status"] > 0 && (
                <>
                  {data[data.section+"_status"] === 1 && (
                    <>
                      In Review
                    </>
                  )}
                  {data[data.section+"_status"] === 2 && (
                    <>
                      Public<br />
                      Approved {approvedDate}<br />
                      by {approvedByName}
                    </>
                  )}
                </>
              )}
            </>
          )}
          {props.isReadOnly && (
            <div style={{ flexDirection: "row" }}>
              {user.is_admin && data[data.section+"_status"] === 1 && (
                <ThumbUp style={{ cursor: "pointer", color: COLOR_BRAND, marginRight: 20 }} onClick={setApproveModal} />
              )}
              <Edit style={{ color: COLOR_BRAND, cursor: "pointer", marginTop: 20 }} onClick={setReadOnly} />
            </div>
          )}
          <Modal
            open={viewModal}
            style={{ display: "flex", justifyContent: "center", alignItems: "center" }}
          >
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: 600, height: 200, backgroundColor: "#fff", flexDirection: "column" }}>
              <h2>Approve this content?</h2>
              <div style={{ flexDirection: "row" }}>
                <Button style={{ color: COLOR_BRAND, backgroundColor: "#fff", marginRight: 10 }} size="medium" onClick={() => { setViewModal(false); }}>
                  CANCEL
                </Button>
                <Button style={{ color: "#fff", backgroundColor: COLOR_BRAND }} variant="contained" size="medium" onClick={approve}>
                  APPROVE
                </Button>
              </div>
            </div>
          </Modal>
          <Snackbar
            open={isSnackbar}
            autoHideDuration={1000}
            onClose={snackbarClose}
            message={snackbarMessage}
            action={<div />}
            key={Date.now()}
          />
        </div>
      )}
    </>
  );
}