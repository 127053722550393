import React, {useEffect, useState} from "react";
import { makeStyles } from "@mui/styles";
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import axios from "axios";
import { useSelector, useDispatch } from 'react-redux';
import Store from '../utilities/Store';
import { API_ENDPOINT } from "../consts";

const baseCustomerUrl = "https://composer.questadventure.app/";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flex: 1,
    justifyContent: "center",
  },
  innerContainer: {
    width: 300,
    height: 250,
    backgroundColor: "#f1f1f1",
    marginTop: 80,
    padding: 20
  },
  title: {
    width: "100%"
  },
  signInButton: {
    marginTop: 20
  }
}));

export const SignIn = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const signIn = () => {
    axios.post(API_ENDPOINT+'?func=authenticate', {
      username: username,
      password: password
    },{
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    })
    .then(res => {
      if(res.status === 200 && res.data.id !== -1) {
        const user = {
          id: res.data.id,
          name: res.data.name,
          is_admin: res.data.is_admin
        };
        dispatch({ type: 'UPDATE_USER', payload: user });
        localStorage.setItem('userData', JSON.stringify(user));
        props._setIsAuthenticated(true);
      } else {
        alert("Sign in failed");
      }
    });
  }

  return (
    <div className={classes.container}>
      <div className={classes.innerContainer}>
        <h1>Sign In</h1>
        <TextField
          name="username"
          id="username" 
          label="Username" 
          variant="standard" 
          className={classes.title} 
          onChange={e => {
            setUsername(e.target.value);
          }}
          value={username}
        />
        <TextField
          name="password"
          id="password" 
          label="Password" 
          type="password"
          variant="standard" 
          className={classes.title} 
          onChange={e => {
            setPassword(e.target.value);
          }}
          value={password}
        />
        <div className={classes.signInButton}>
          <Button variant="contained" onClick={() => { signIn(); }}>Sign In</Button>
        </div>
      </div>
    </div>
  );
  
}