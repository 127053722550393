import React, {useEffect, useState} from "react";
import { useNavigate, NavLink } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import axios from "axios";
import Snackbar from '@mui/material/Snackbar';
import TextField from '@mui/material/TextField';
import { Delete, Edit, Close } from "@mui/icons-material";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import TablePagination from '@mui/material/TablePagination';
import Autocomplete from '@mui/material/Autocomplete';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import { Dots } from "react-activity";
import "react-activity/dist/library.css";
import { API_ENDPOINT, COLOR_BRAND } from "../consts";

const useStyles = makeStyles((theme) => ({
  container: {
    flex: 1
  },
  innerContainer: {
    display: "flex",
    width: "100%"
  },
  menuContainer: {
    display: "flex",
    justifyContent: "flex-end",
    paddingRight: 60
  },
  box: {
    width: "100%",
    maxWidth: 1200,
    minHeight: 600,
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 20,
    paddingBottom: 120,
    backgroundColor: "#fff",
    webkitBoxShadow: "1px 2px 3px #00000033",
    mozBoxShadow: "1px 2px 3px #00000033",
    boxShadow: "1px 2px 3px #00000033",
    borderRadius: 5
  },
  filterString: {
    width: 400,
  },
  headerContainer: {
    width: 1200,
    display: "flex",
    flexDirection: "row"
  },
  headerInnerContainerLeft: {
    display: "flex",
    flex: 1,
    alignItems: "center"
  },
  headerInnerContainerRight: {
    display: "flex",
    flex: 1,
    alignItems: "center",
    justifyContent: "flex-end"
  },
  formInput: {
    width: "90%",
    backgroundColor: "#fff"
  },
  horizontalSpacerLarge: {
    width: "100%",
    height: 80
  },
  horizontalSpacerSmall: {
    width: "100%",
    height: 20
  },
  buttonContainer: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: 40,
    marginBottom: 80
  },
}));

export function Ingredients () {
  const [rowsInit, setRowsInit] = useState([]);
  const [rows, setRows] = useState([]);
  const classes = useStyles();
  const navigate = useNavigate();
  const [isSnackbar, setIsSnackbar] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState(false);
  const [paginationLimit, setPaginationLimit] = useState(10);
  const [paginationPage, setPaginationPage] = useState(0);
  const [isAlertEdit, setIsAlertEdit] = useState(false);
  const [isAlertDelete, setIsAlertDelete] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [selectedName, setSelectedName] = useState("");
  const [filterText, setFilterText] = useState("");
  const [selectedRating, setSelectedRating] = useState("");
  const [name, setName] = useState("");
  const [id, setId] = useState(-1);
  const [modalSynonym, setModalSynonym] = useState(false);
  const [modalSynonymText, setModalSynonymText] = useState("");
  const [ingredientData, setIngredientData] = useState([]);
  const [synonyms, setSynonyms] = useState([]);
  const [origins, setOrigins] = useState([]);
  const [idOrigin, setIdOrigin] = useState("");
  const [originLabel, setOriginLabel] = useState("");
  const [functions, setFunctions] = useState([]);
  const [functionsUsed, setFunctionsUsed] = useState([]);
  const [keyFunctions, setKeyFunctions] = useState(false);
  const [sentencesH, setSentencesH] = useState([]);
  const [sentencesHUsed, setSentencesHUsed] = useState([]);
  const [keySentencesH, setKeySentencesH] = useState(false);
  const [sentencesG, setSentencesG] = useState([]);
  const [sentencesGUsed, setSentencesGUsed] = useState([]);
  const [keySentencesG, setKeySentencesG] = useState(false);
  const [suggestedRating, setSuggestedRating] = useState(0);
  const [rating, setRating] = useState("");
  const [gasNo, setGasNo] = useState("");
  const [einecs, setEinecs] = useState("");
  const [resources, setResources] = useState("");
  const [environmentalGrade, setEnvironmentalGrade] = useState("");
  const [environmentalReport, setEnvironmentalReport] = useState("");
  const [humanGrade, setHumanGrade] = useState("");
  const [humanReport, setHumanReport] = useState("");

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if(rating == "") {
      setRating(suggestedRating);
    }
  }, [suggestedRating]);

  const getData = async () => {
    setIsLoading(true);
    axios.get(API_ENDPOINT+'?func=get_ingredients')
    .then(res => {
      let data = res.data;
      if(res.status === 200) {
        setRowsInit(data);
        setRows(data);
        setIsLoading(false);
      }
    });
  };

  const getIngredientDetails = async (id) => {
    setIsLoading(true);
    axios.get(API_ENDPOINT+'?func=get_ingredient_details&id='+ id)
    .then(res => {
      if(res.status === 200) {
        setIngredientData(res.data);
        if(id > -1) {
          setId(id);
          setName(res.data.name);
          setSynonyms(res.data.synonyms);
          console.info(res.data.rating);
          setRating(res.data.rating);
          setGasNo(res.data.gas_no);
          setEinecs(res.data.einecs);
          setResources(res.data.resources);
          setEnvironmentalGrade(res.data.environmental_grade);
          setEnvironmentalReport(res.data.environmental_report);
          setHumanGrade(res.data.human_grade);
          setHumanReport(res.data.human_report);
          setFunctions(res.data.functions);
        }
        setOrigins(res.data.origin);
        filterOrigins(res.data);
        filterFunctions(res.data);
        filterSentences(res.data);
        setIsLoading(false);
      }
    });
  };

  const filterOrigins = (data) => {
    for(let i=0; i<data.origin.length; i++) {
      if(data.origin[i].isSaved === 1) {
        setIdOrigin(data.origin[i].id);
        setOriginLabel(data.origin[i].label);
      }
    }
  };

  const filterFunctions = (data) => {
    let functionsUnused = [];
    let functionsUsed = [];
    for(let i=0; i<data.functions.length; i++) {
      if(data.functions[i].isSaved === 1) {
        functionsUsed[functionsUsed.length] = data.functions[i];
      } else {
        functionsUnused[functionsUnused.length] = data.functions[i];
      }
    }
    setFunctions(functionsUnused);
    setFunctionsUsed(functionsUsed);
  };

  const filterSentences = (data) => {
    let tempSuggestedRating = 0;
    let sentencesHUnused = [];
    let sentencesHUsed = [];
    for(let i=0; i<data.sentencesH.length; i++) {
      if(data.sentencesH[i].isSaved === 1) {
        sentencesHUsed[sentencesHUsed.length] = data.sentencesH[i];
        tempSuggestedRating += parseFloat(data.sentencesH[i].rating);
      } else {
        sentencesHUnused[sentencesHUnused.length] = data.sentencesH[i];
      }
    }
    setSentencesH(sentencesHUnused);
    setSentencesHUsed(sentencesHUsed);
    let sentencesGUnused = [];
    let sentencesGUsed = [];
    for(let i=0; i<data.sentencesG.length; i++) {
      if(data.sentencesG[i].isSaved === 1) {
        sentencesGUsed[sentencesGUsed.length] = data.sentencesG[i];
        tempSuggestedRating += parseFloat(data.sentencesG[i].rating);
      } else {
        sentencesGUnused[sentencesGUnused.length] = data.sentencesG[i];
      }
    }
    setSentencesG(sentencesGUnused);
    setSentencesGUsed(sentencesGUsed);
    setSuggestedRating(() => {
      console.info("tempSuggestedRating: "+ tempSuggestedRating);
      var result = null;
      if(tempSuggestedRating < -2) {
        result = -2;
      } else if(tempSuggestedRating > 2) {
        result = 2;
      } else {
        result = tempSuggestedRating;
      }
      return result;
    });
  };

  const snackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setIsSnackbar(false);
  };

  const filterRows = async (string) => {
    setFilterText(string);
    var foundRows = [];
    if(string.length == 0) {
      foundRows = rowsInit;
    } else {
      for(var i=0; i<rowsInit.length; i++) {
        if(
          rowsInit[i].name.toLowerCase().indexOf(string.toLowerCase()) !== -1  ||
          rowsInit[i].gas_no.toLowerCase().indexOf(string.toLowerCase()) !== -1  ||
          rowsInit[i].einecs.toLowerCase().indexOf(string.toLowerCase()) !== -1
          )
        {
          foundRows[foundRows.length] = rowsInit[i];
          continue;
        }
        for(var x=0; x<rowsInit[i].synonyms.length; x++){
          if(rowsInit[i].synonyms[x].label.toLowerCase().indexOf(string.toLowerCase()) !== -1) {
            foundRows[foundRows.length] = rowsInit[i];
            break;
          }
        }
      }
    }
    setRows(foundRows);
  };

  const handleChangePage = (e, newNumber) => {
    setPaginationPage(newNumber);
  };

  const handleChangeRowsPerPage = (e) => {
    setPaginationLimit(parseInt(e.target.value));
    setPaginationPage(0);
  };

  const openAlertEdit = (x) => {
    setSelectedIndex(x);
    setIsAlertEdit(true);
    if(x > -1) {
      setId(rows[x].id);
      setSelectedName(rows[x].description);
      setSelectedRating(rows[x].rating);
      getIngredientDetails(rows[x].id);
    } else {
      setId(-1);
      getIngredientDetails(-1);
    }
  };

  const openAlertDelete = (x) => {
    setSelectedName(rows[x].description);
    setSelectedIndex(x);
    setIsAlertDelete(true);
  };

  const closeAlertEdit = () => {
    setIsAlertEdit(false);
    setIngredientData([]);
    setName("");
    setSynonyms([]);
    setRating("");
    setGasNo("");
    setEinecs("");
    setResources("");
    setEnvironmentalGrade("");
    setEnvironmentalReport("");
    setHumanGrade("");
    setHumanReport("");
    setOrigins([]);
    setFunctions([]);
    setId(-1);
    setSuggestedRating(0);
    setIdOrigin(0);
    setOriginLabel("");
    setFunctionsUsed([]);
    setSentencesHUsed([]);
    setSentencesGUsed([]);
  };

  const closeAlertDelete = () => {
    setIsAlertDelete(false);
    setSelectedIndex(-1);
    setSelectedName("");
  };

  const saveData = () => {
    console.info("id23: "+ id);
    console.info(synonyms);
    setIsLoading(true);
    axios.post(API_ENDPOINT+"?func=update_ingredient", {
      id: id,
      name: name,
      synonyms: JSON.stringify(synonyms),
      gasNo: gasNo,
      einecs: einecs,
      rating: rating,
      environmentalGrade: environmentalGrade,
      environmentalReport: environmentalReport,
      humanGrade: humanGrade,
      humanReport: humanReport,
      resources: resources,
      origin: idOrigin,
      functions: JSON.stringify(functionsUsed),
      sentencesH: JSON.stringify(sentencesHUsed),
      sentencesG: JSON.stringify(sentencesGUsed)
    },{
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    })
    .then(res => {
      if(res.status === 200) {
        console.info(res.data);
        getData();
        closeAlertEdit();
        setSnackbarMessage("Ingredient saved successfully");
        setIsSnackbar(true);
      }
    });
  }

  const deleteData = () => {
    var id = rows[selectedIndex].id;
    axios.get(API_ENDPOINT+'?func=delete_ingredient&id='+ id)
    .then(res => {
      if(res.status === 200) {
        getData();
        closeAlertDelete();
        setSnackbarMessage("Ingredient deleted successfully");
        setIsSnackbar(true);
      }
    });
  }

  return (
    <>
      <div className={classes.container} id="scrollContainer">
        <div className={classes.headerContainer}>
          <div className={classes.headerInnerContainerLeft}>
            <h1>Ingredients</h1>
          </div>
          <div className={classes.headerInnerContainerRight}>
            {}
            <a style={{ color: COLOR_BRAND }} href="javascript: void(0);" onClick={() => { openAlertEdit(-1); }}>New Ingredient</a>
          </div>
        </div>
        <div className={classes.innerContainer}>
          <div className={classes.box}>
            {isLoading ? <Dots /> : (
              <>
                <TextField 
                  id="standard-basic" 
                  label="Search" 
                  variant="outlined" 
                  className={classes.filterString} 
                  value={filterText}
                  onChange={value => {
                    filterRows(value.target.value);
                  }}
                />
                <TablePagination
                  component="div"
                  count={rows.length}
                  page={paginationPage}
                  onPageChange={handleChangePage}
                  rowsPerPage={paginationLimit}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  style={{ marginTop: 40 }}
                />
                <List>
                <ListItem>
                  <ListItemText style={{ flex: 7.75 }} primary={"Name"} />
                  <ListItemText style={{ flex: 1.5 }} primary={"GAS NO"} />
                  <ListItemText style={{ flex: 1.5 }} primary={"EINECS"} />
                  <ListItemText style={{ flex: 1 }} primary={"Rating"} />
                  <ListItemText style={{ flex: 3 }} primary={"Status"} />
                </ListItem>
                {rows.map((item, index) => (
                  <>
                    {index >= (paginationLimit * paginationPage) && index < ((paginationLimit * paginationPage)+paginationLimit) && (
                      <ListItem>
                        <ListItemButton onClick={() => { openAlertEdit(index); }}>
                          <ListItemText style={{ flex: 10 }} primary={item.name} />
                          <ListItemText style={{ flex: 2 }} primary={item.gas_no} />
                          <ListItemText style={{ flex: 2 }} primary={item.einecs} />
                          <ListItemText style={{ flex: 1 }} primary={item.rating === -9999 ? '' : item.rating} />
                          <ListItemText style={{ flex: 1.5 }} primary={item.status} />
                          <ListItemIcon>
                              <Edit style={{ color: COLOR_BRAND }} />
                          </ListItemIcon>
                        </ListItemButton>
                        <ListItemIcon>
                          <ListItemButton onClick={() => { openAlertDelete(index); }}>
                            <Delete style={{ color: COLOR_BRAND }} />
                          </ListItemButton>
                        </ListItemIcon>
                      </ListItem>
                    )}
                  </>
                ))}
                </List>
                <TablePagination
                  component="div"
                  count={rows.length}
                  page={paginationPage}
                  onPageChange={handleChangePage}
                  rowsPerPage={paginationLimit}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </>
            )}
            
          </div>
        </div>
      </div>
      <Modal
        open={isAlertEdit}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        style={{ display: "flex", alignItems: "flex-start" }}
      >
        <div style={{ width: "100%", height: "100%", display: "flex", alignItems: "flex-start", justifyContent: "center", overflow: "hidden" }}>
          <div style={{ position: "relative", width: "88%", maxWidth: 1200, height: "94%", padding: "2%", top: "1%", backgroundColor: "#fff", overflow: "auto", display: "flex", flexDirection: "column", borderRadius: 5 }}>
            <div style={{ position: "fixed", top: 10, right: 10, borderRadius: 20, width: 40, height: 40, cursor: "pointer", display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: "#ffffff" }} onClick={()=> {closeAlertEdit(); }}>
              <Close />
            </div>
            <div style={{ display: "flex", width: "100%", flexDirection: "row" }}>
              <div style={{ display: "flex", flex: 1}}>
                <h2>{selectedIndex == -1 ? "New" : "Edit"} Ingredient</h2>
              </div>
            </div>
            {isLoading ? <Dots /> : (
              <>
                <br />
                <div style={{ marginBottom: 20 }}>
                  <TextField 
                    id="standard-basic" 
                    label="INCI Name" 
                    variant="outlined" 
                    className={classes.formInput} 
                    onChange={(value) => {
                      setName(value.target.value);
                    }}
                    value={name}
                  />
                </div>
                <div style={{ marginBottom: 80 }}>
                  <div className={classes.horizontalSpacerSmall} />
                  <div>
                    <h5>Synonyms</h5><br />
                    <Stack direction="row" spacing={1} className={classes.formInput} style={{ flexWrap: "wrap", backgroundColor: "transparent", marginTop: -30 }} >
                      {synonyms.map((item, index) => (
                        <div>
                          <Chip 
                            label={item.label}
                            onDelete={() => {
                              var tempData = [...synonyms];
                              for(var i = 0; i<tempData.length; i++) {
                                if(tempData[i].label === item.label) {
                                  tempData.splice(i, 1);
                                }
                              }
                              setSynonyms(tempData);
                            }} 
                            sx={{ justifyContent: "flex-start", marginBottom: 1 }}
                          />
                        </div>
                      ))}
                      <div 
                        onClick={() => { setModalSynonym(true); }}
                        style={{ width: 30, height: 30, backgroundColor: COLOR_BRAND+"99", borderRadius: 15, display: "flex", color: "#fff", fontWeight: "bold", justifyContent: "center", alignItems: "center", cursor: "pointer" }}
                      >
                        +
                      </div>
                    </Stack>
                  </div>
                </div>
                <div style={{ display: "flex", flexDirection: "row", marginBottom: 20 }}>
                  <div style={{ flex: 1 }}>
                    <TextField 
                      id="standard-basic" 
                      label="GAS NO" 
                      variant="outlined" 
                      className={classes.formInput} 
                      onChange={(value) => {
                        setGasNo(value.target.value);
                      }}
                      value={gasNo}
                    />
                  </div>
                  <div style={{ flex: 1 }}>
                    <TextField 
                      id="standard-basic" 
                      label="EINECS NO" 
                      variant="outlined" 
                      className={classes.formInput} 
                      onChange={(value) => {
                        setEinecs(value.target.value);
                      }}
                      value={einecs}
                    />
                  </div>
                  <div style={{ flex: 1 }}>
                    <TextField 
                      id="standard-basic" 
                      label="Final Rating" 
                      variant="outlined" 
                      className={classes.formInput} 
                      onChange={(value) => {
                        setRating(value.target.value);
                      }}
                      value={rating}
                    />
                    <b style={{ marginTop: 5, fontSize: 10 }}>Suggested rating: {suggestedRating}</b>
                  </div>
                </div>
                <div style={{ display: "flex", flexDirection: "row", marginBottom: 20 }}>
                  <div style={{ flex: 1 }}>
                    <Autocomplete
                      disablePortal
                      id="combo-box-demo"
                      className={classes.formInput} 
                      options={origins}
                      value={originLabel}
                      renderInput={(params) => <TextField {...params} label="Origin" />}
                      onChange={(event, newValue) => {
                        if(newValue) {
                          setIdOrigin(newValue.id);
                          setOriginLabel(newValue.label);
                        } else {
                          setIdOrigin(0);
                          setOriginLabel("");
                        }
                      }}
                    />
                  </div>
                  <div style={{ flex: 1 }}>
                    <Autocomplete
                      key={keyFunctions}
                      disablePortal
                      id="combo-box-demo"
                      className={classes.formInput} 
                      options={functions}
                      renderInput={(params) => <TextField {...params} label="Functions" />}
                      onChange={(event, newValue) => {
                        var tempData = [...ingredientData.functions];
                        for(var i = 0; i<tempData.length; i++) {
                          if(tempData[i].id === newValue.id) {
                            tempData[i].isSaved = 1;
                          }
                        }
                        var newIngredientData = ingredientData;
                        newIngredientData.functions = tempData;
                        setIngredientData(newIngredientData);
                        filterFunctions(newIngredientData);
                        setKeyFunctions(keyFunctions === false ? true : false);
                      }}
                    />
                    <div className={classes.horizontalSpacerSmall} />
                    <Stack direction="column" spacing={1} className={classes.formInput} style={{ backgroundColor: "transparent" }} >
                      {functionsUsed.map((item, index) => (
                        <div>
                          <Chip 
                            label={item.label}
                            onDelete={() => {
                              var tempData = [...ingredientData.functions];
                              for(var i = 0; i<tempData.length; i++) {
                                console.info(tempData[i].id);
                                if(tempData[i].id === item.id) {
                                  tempData[i].isSaved = 0;
                                }
                              }
                              var newIngredientData = ingredientData;
                              newIngredientData.functions = tempData;
                              setIngredientData(newIngredientData);
                              filterFunctions(newIngredientData);
                              setKeyFunctions(keyFunctions === false ? true : false);
                            }} 
                            sx={{ justifyContent: "flex-start" }}
                          />
                        </div>
                      ))}
                    </Stack>
                  </div>
                </div>
                <div style={{ marginBottom: 20 }}>
                  <h4>Environmental impact assessment</h4>
                  <TextField 
                    id="standard-basic" 
                    label="Grade" 
                    variant="outlined" 
                    className={classes.formInput} 
                    style={{ width: 100 }}
                    onChange={(value) => {
                      setEnvironmentalGrade(value.target.value);
                    }}
                    value={environmentalGrade}
                  />
                  <div className={classes.horizontalSpacerSmall} />
                  <TextField
                    label="Evaluation Report"
                    multiline
                    className={classes.formInput} 
                    rows={4}
                    onChange={(value) => {
                      setEnvironmentalReport(value.target.value);
                    }}
                    value={environmentalReport}
                  />
                </div>
                <div style={{ marginBottom: 20 }}>
                  <h4>Human health impact assessment</h4>
                  <TextField 
                    id="standard-basic" 
                    label="Grade" 
                    variant="outlined" 
                    className={classes.formInput}
                    style={{ width: 100 }}
                    onChange={(value) => {
                      setHumanGrade(value.target.value);
                    }}
                    value={humanGrade}
                  />
                  <div className={classes.horizontalSpacerSmall} />
                  <TextField
                    label="Evaluation Report"
                    multiline
                    className={classes.formInput} 
                    rows={4}
                    onChange={(value) => {
                      setHumanReport(value.target.value);
                    }}
                    value={humanReport}
                  />
                </div>
                <div style={{ marginBottom: 20 }}>
                  <h4>H Sentences</h4>
                  <Autocomplete
                    key={keySentencesH}
                    disablePortal
                    id="combo-box-demo"
                    className={classes.formInput} 
                    options={sentencesH}
                    renderInput={(params) => <TextField {...params} label="H-Sentences" />}
                    onChange={(event, newValue) => {
                      var tempData = [...ingredientData.sentencesH];
                      for(var i = 0; i<tempData.length; i++) {
                        console.info(tempData[i].id);
                        if(tempData[i].id === newValue.id) {
                          tempData[i].isSaved = 1;
                        }
                      }
                      var newIngredientData = ingredientData;
                      newIngredientData.sentencesH = tempData;
                      setIngredientData(newIngredientData);
                      filterSentences(newIngredientData);
                      setKeySentencesH(keySentencesH === false ? true : false);
                    }}
                  />
                  <div className={classes.horizontalSpacerSmall} />
                  <Stack direction="column" spacing={1} className={classes.formInput} style={{ backgroundColor: "transparent" }} >
                    {sentencesHUsed.map((item, index) => (
                      <div>
                        <Chip 
                          label={item.label}
                          onDelete={() => {
                            var tempData = [...ingredientData.sentencesH];
                            for(var i = 0; i<tempData.length; i++) {
                              console.info(tempData[i].id);
                              if(tempData[i].id === item.id) {
                                tempData[i].isSaved = 0;
                              }
                            }
                            var newIngredientData = ingredientData;
                            newIngredientData.sentencesH = tempData;
                            setIngredientData(newIngredientData);
                            filterSentences(newIngredientData);
                            setKeySentencesH(keySentencesH === false ? true : false);
                          }} 
                          sx={{ justifyContent: "flex-start" }}
                        />
                      </div>
                    ))}
                  </Stack>
                </div>
                <div style={{ marginBottom: 60 }}>
                  <h4>G Sentences</h4>
                  <Autocomplete
                    key={keySentencesG}
                    disablePortal
                    id="combo-box-demo"
                    className={classes.formInput} 
                    options={sentencesG}
                    renderInput={(params) => <TextField {...params} label="G-Sentences" />}
                    onChange={(event, newValue) => {
                      var tempData = [...ingredientData.sentencesG];
                      for(var i = 0; i<tempData.length; i++) {
                        console.info(tempData[i].id);
                        if(tempData[i].id === newValue.id) {
                          tempData[i].isSaved = 1;
                        }
                      }
                      var newIngredientData = ingredientData;
                      newIngredientData.sentencesG = tempData;
                      setIngredientData(newIngredientData);
                      filterSentences(newIngredientData);
                      setKeySentencesG(keySentencesG === false ? true : false);
                    }}
                  />
                  <div className={classes.horizontalSpacerSmall} />
                  <Stack direction="column" spacing={1} className={classes.formInput} style={{ backgroundColor: "transparent" }} >
                    {sentencesGUsed.map((item, index) => (
                      <div>
                        <Chip 
                          label={item.label}
                          onDelete={() => {
                            var tempData = [...ingredientData.sentencesG];
                            for(var i = 0; i<tempData.length; i++) {
                              console.info(tempData[i].id);
                              if(tempData[i].id === item.id) {
                                tempData[i].isSaved = 0;
                              }
                            }
                            var newIngredientData = ingredientData;
                            newIngredientData.sentencesG = tempData;
                            setIngredientData(newIngredientData);
                            filterSentences(newIngredientData);
                            setKeySentencesG(keySentencesG === false ? true : false);
                          }} 
                          sx={{ justifyContent: "flex-start" }}
                        />
                      </div>
                    ))}
                  </Stack>
                </div>
                <div style={{ marginBottom: 20 }}>
                  <TextField
                    label="Sources"
                    multiline
                    className={classes.formInput} 
                    rows={4}
                    value={resources}
                    onChange={(value) => {
                      setResources(value.target.value);
                    }}
                  />
                </div>
                <div className={classes.buttonContainer}>
                  <Button style={{ color: "#fff", backgroundColor: COLOR_BRAND }} variant="contained" size="medium" onClick={() => { saveData(); }}>
                    SAVE
                  </Button>
                </div>
              </>
            )}
          </div>
        </div>
      </Modal>
      <Modal
        open={modalSynonym}
        style={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: 600, height: 200, backgroundColor: "#fff", flexDirection: "column" }}>
          <TextField 
            id="standard-basic" 
            label="Add a synonym" 
            variant="outlined" 
            className={classes.formInput} 
            style={{ marginBottom: 20 }}
            onChange={(value) => {
              setModalSynonymText(value.target.value);
            }}
            value={modalSynonymText}
          />
          <div style={{ flexDirection: "row" }}>
            <Button variant="contained" size="medium" onClick={() => { 
              var tempSynonyms = [...synonyms];
              var newSynonym = {
                id: -1,
                label: modalSynonymText
              };
              tempSynonyms.push(newSynonym);
              setSynonyms(tempSynonyms); 
              setModalSynonymText("");
              setModalSynonym(false);
            }}>
              SAVE
            </Button>
            &nbsp;
            &nbsp;
            <Button variant="outlined" size="medium" onClick={() => { setModalSynonym(false); }}>
              CANCEL
            </Button>
          </div>
        </div>
      </Modal>
      <Dialog
        open={isAlertDelete}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <div>
            <h3>Delete confirmation</h3>
            <b>Ingredient:</b> {selectedName}
          </div>
        </DialogContent>
        <DialogActions>
          <Button style={{ color: COLOR_BRAND, backgroundColor: "#fff" }} onClick={closeAlertDelete}>Cancel</Button>
          <Button style={{ color: "#fff", backgroundColor: COLOR_BRAND }} onClick={deleteData} autoFocus>Delete</Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={isSnackbar}
        autoHideDuration={1000}
        onClose={snackbarClose}
        message={snackbarMessage}
        action={<div />}
        key={Date.now()}
      />
    </>
  );
  
}