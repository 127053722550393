import React, {useEffect, useState} from "react";
import { useNavigate, NavLink } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import { DataGrid } from '@mui/x-data-grid';
import axios from "axios";
import TimeAgo from "react-timeago";
import { ArrowUpward, ArrowDownward } from "@mui/icons-material";
import Snackbar from '@mui/material/Snackbar';
import TextField from '@mui/material/TextField';
import { API_ENDPOINT, COLOR_BRAND } from "../consts";

const useStyles = makeStyles((theme) => ({
  container: {
    flex: 1,
  },
  innerContainer: {
    display: "flex",
    justifyContent: "center",
    width: "100%"
  },
  menuContainer: {
    display: "flex",
    justifyContent: "flex-end",
    paddingRight: 60
  },
  box: {
    width: "100%",
    maxWidth: 1200,
    height: 600,
    padding: 20
  },
  filterString: {
    width: 300,
  },
  datagrid: {
    marginTop: 40
  },
  linkColor: {
    color: "#00ff00"
  }
}));

export function PushNotifications (props) {
  const [rowsInit, setRowsInit] = useState([]);
  const [rows, setRows] = useState([]);
  const classes = useStyles();
  const [isSnackbar, setIsSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState(false);

  useEffect(() => {
    getPushNotifications();
  }, []);

  const getPushNotifications = async () => {
    axios.get(API_ENDPOINT+'?func=get_push_notifications')
    .then(res => {
      if(res.status === 200) {
        setRowsInit(res.data);
        setRows(res.data);
      }
    });
  };

  const snackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setIsSnackbar(false);
  };

  const filterRows = async (string) => {
    var foundRows = [];
    if(string.length == 0) {
      getPushNotifications();
    } else {
      for(var i=0; i<rowsInit.length; i++) {
        if(rowsInit[i].title.toLowerCase().indexOf(string.toLowerCase()) !== -1 || rowsInit[i].body.toLowerCase().indexOf(string.toLowerCase()) !== -1) {
          foundRows[foundRows.length] = rowsInit[i];
        }
      }
    }
    setRows(foundRows);
  };

  const columns = [
    {
      field: 'recipients',
      headerName: 'To',
      width: 200,
      editable: false,
      sortable: false
    },
    {
      field: 'created',
      headerName: 'Sent',
      width: 250,
      editable: false,
      sortable: false,
      renderCell: (cellValues) => {
        return (
          <TimeAgo date={(cellValues.row.created*1000)} />
        );
      }
    },
    {
      field: 'title',
      headerName: 'Title / Body',
      width: 120,
      flex: 1,
      editable: false,
      sortable: false,
      renderCell: (cellValues) => {
        return (
          <div style={{flexDirection: "column"}}>
            <b>{cellValues.row.title}</b><br />
            {cellValues.row.body}
          </div>
        );
      }
    }
  ];

  return (
    <>
      <div className={classes.container}>
        <h1>Push Notifications</h1>
        <div className={classes.menuContainer}>
          <NavLink to="/push_notifications_send" style={{ color:COLOR_BRAND }} state={{ id: -1 }}>Send a Push Notification</NavLink>
        </div>
        <div className={classes.innerContainer}>
          <div className={classes.box}>
            <TextField 
              id="standard-basic" 
              label="Search" 
              variant="standard" 
              className={classes.filterString} 
              onChange={value => {
                filterRows(value.target.value);
              }}
            />
            <DataGrid
              className={classes.datagrid}
              rows={rows}
              columns={columns}
              pageSize={15}
              rowsPerPageOptions={[5]}
              autoHeight
              disableColumnMenu
              disableColumnSelector
              hideFooter
              components={{
                NoRowsOverlay: () => (
                  <div className={classes.noResuls}>
                    
                  </div>
                )
              }}
            />
          </div>
        </div>
      </div>
      <Snackbar
        open={isSnackbar}
        autoHideDuration={2300}
        onClose={snackbarClose}
        message={snackbarMessage}
        action={<div />}
        key={Date.now()}
      />
    </>
  );
  
}