import React, {useEffect, useState} from "react";
import { useNavigate, NavLink } from "react-router-dom";
import { makeStyles, propsToClassKey } from "@mui/styles";
import { DataGrid } from '@mui/x-data-grid';
import axios from "axios";
import Snackbar from '@mui/material/Snackbar';
import TextField from '@mui/material/TextField';
import { Close, Edit } from "@mui/icons-material";
import { Dots } from "react-activity";
import Avatar from '@mui/material/Avatar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import TablePagination from '@mui/material/TablePagination';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import { API_ENDPOINT, IMAGES_ENDPOINT, COLOR_BRAND, COLOR_INDICATOR1, COLOR_INDICATOR2, COLOR_INDICATOR3 } from "../../consts";
import { ProductGroup } from  './ProductGroup'

const useStyles = makeStyles((theme) => ({
  container: {
    flex: 1
  },
  innerContainer: {
    display: "flex",
    width: "100%"
  },
  menuContainer: {
    display: "flex",
    justifyContent: "flex-end",
    paddingRight: 60
  },
  box: {
    width: "100%",
    maxWidth: 1200,
    minHeight: 600,
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 20,
    paddingBottom: 120,
    backgroundColor: "#fff",
    webkitBoxShadow: "1px 2px 3px #00000033",
    mozBoxShadow: "1px 2px 3px #00000033",
    boxShadow: "1px 2px 3px #00000033",
    borderRadius: 5
  },
  filterString: {
    width: 400,
  },
  headerContainer: {
    width: 1200,
    display: "flex",
    flexDirection: "row"
  },
  headerInnerContainerLeft: {
    display: "flex",
    flex: 1,
    alignItems: "center"
  },
  headerInnerContainerRight: {
    display: "flex",
    flex: 1,
    alignItems: "center",
    justifyContent: "flex-end"
  },
  input_string: {
    width: "80%",
    maxWidth: 750,
    marginBottom: 20,
    backgroundColor: "#ffffff"
  },
}));

export function Products () {
  const [rowsInit, setRowsInit] = useState([]);
  const [rows, setRows] = useState([]);
  const classes = useStyles();
  const navigate = useNavigate();
  const [isSnackbar, setIsSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState(false);
  const [paginationLimit, setPaginationLimit] = useState(10);
  const [paginationPage, setPaginationPage] = useState(0);
  const [idProduct, setIdProduct] = useState(0);
  const [isNew, setIsNew] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [dataEssentials, setDataEssentials] = useState({});
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [modalProductBarcode, setModalProductBarcode] = useState("");
  const [modalProductLabel, setModalProductLabel] = useState("");
  const [modalProductScreen, setModalProductScreen] = useState(0);
  const [modalProductLookup, setModalProductLookup] = useState(false);

  useEffect(() => {
    getProducts();
  }, []);

  const getProducts = async () => {
    setIsLoading(true);
    console.info(API_ENDPOINT+'?func=get_products');
    axios.get(API_ENDPOINT+'?func=get_products')
    .then(res => {
      if(res.status === 200) {
        setRowsInit(res.data);
        setRows(res.data);
        setIsLoading(false);
      }
    });
  };

  const snackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setIsSnackbar(false);
  };

  const filterRows = async (string) => {
    var foundRows = [];
    if(string.length == 0) {
      getProducts();
    } else {
      for(var i=0; i<rowsInit.length; i++) {
        var isFound = false;
        if(
          rowsInit[i].name.toLowerCase().indexOf(string.toLowerCase()) !== -1 ||
          rowsInit[i].brand.toLowerCase().indexOf(string.toLowerCase()) !== -1
          )
        {
          isFound = true;
          foundRows[foundRows.length] = rowsInit[i];
        }
        if(!isFound) {
          for(var x=0; x<rowsInit[i].synonyms.length; x++) {
            if(rowsInit[i].synonyms[x].name.toLowerCase().indexOf(string.toLowerCase()) !== -1) {
              foundRows[foundRows.length] = rowsInit[i];
              break;
            }
          }
        }
      }
    }
    setRows(foundRows);
  };

  const handleChangePage = (e, newNumber) => {
    setPaginationPage(newNumber);
  };

  const handleChangeRowsPerPage = (e) => {
    setPaginationLimit(parseInt(e.target.value));
    setPaginationPage(0);
  };

  const openModalNew = () => {
    setModalProductBarcode("");
    setModalProductLabel("");
    setIsNew(true);
  };

  const closeModalNew = () => {
    setModalProductBarcode("");
    setModalProductLabel("");
    setModalProductScreen(0);
    setIsNew(false);
    setModalProductLookup(false);
  };

  const noBarcode = () => {
    setModalProductBarcode("");
    setModalProductScreen(1);
  };

  const saveBarcode = () => {
    if(modalProductBarcode.length > 0){
      setModalProductLookup(true);
      axios.post(API_ENDPOINT+"?func=barcode_lookup", {
        id_updatedBy: 12,
        ean: modalProductBarcode
      },{
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      })
      .then(res => {
        if(res.status === 200) {
          setModalProductLookup(false);
          const result = res.data;
          if(result == 0) {
            setModalProductScreen(1);
          } else {
            setSnackbarMessage("This barcode already exists");
            setIsSnackbar(true);
          }
        }
      });
      
    } else { 
      setSnackbarMessage("Please enter a barcode number");
      setIsSnackbar(true);
    }
  };

  const backBarcode = () => {
    setModalProductLabel("");
    setModalProductScreen(0);
  };

  const saveNew = () => {
    if(modalProductLabel.length > 0) {
      setModalProductLookup(true);
      axios.post(API_ENDPOINT+"?func=create_product", {
        id_updatedBy: 12,
        name: modalProductLabel,
        ean: modalProductBarcode
      },{
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      })
      .then(res => {
        var newId = res.data;
        console.info("newId: "+ newId);
        if(res.status === 200) {
          closeModalNew();
          setIsLoading(true);
          axios.get(API_ENDPOINT+'?func=get_products')
          .then(res2 => {
            if(res2.status === 200) {
              console.info(res2.data);
              setRowsInit(res2.data);
              setRows(res2.data);
              for(var i=0; i<res2.data.length; i++) {
                if(res2.data[i].id == newId) {
                  setSelectedIndex(i);
                  setIsEdit(true);
                  console.info(API_ENDPOINT+'?func=get_product_details&id='+ newId);
                  axios.get(API_ENDPOINT+'?func=get_product_details&id='+ newId)
                  .then(res3 => {
                    if(res3.status === 200) {
                      setDataEssentials(res3.data);
                      setIsLoading(false);
                      setIsNew(false);
                      setSnackbarMessage("Product created successfully");
                      setIsSnackbar(true);
                    }
                  });
                }
              }
            }
          });
        }
      });
    } else {
      setSnackbarMessage("Please enter a product name");
      setIsSnackbar(true);
    }
  };

  const openEdit = (x) => {
    setSelectedIndex(x);
    setIsEdit(true);
    setIsLoading(true);
    console.info(API_ENDPOINT+'?func=get_product_details&id='+ rows[x].id);
    axios.get(API_ENDPOINT+'?func=get_product_details&id='+ rows[x].id)
    .then(res => {
      if(res.status === 200) {
        setIdProduct(rows[x].id);
        setDataEssentials(res.data);
        setIsLoading(false);
      }
    });
    
  };

  const closeEdit =() => {
    getProducts();
    setIsEdit(false);
  };

  return (
    <>
      <div className={classes.container}>
        <div className={classes.headerContainer}>
          <div className={classes.headerInnerContainerLeft}>
            <h1>Products</h1>
          </div>
          <div className={classes.headerInnerContainerRight}>
            {}
            <a style={{ color: COLOR_BRAND }} href="javascript: void(0);" onClick={() => { openModalNew(); }}>New Product</a>
          </div>
        </div>
        <div className={classes.innerContainer}>
          {isLoading ? <Dots /> : (
            <div className={classes.box}>
              <TextField 
                id="standard-basic" 
                label="Search" 
                variant="outlined" 
                className={classes.filterString} 
                onChange={value => {
                  filterRows(value.target.value);
                }}
              />
              <TablePagination
                component="div"
                count={rows.length}
                page={paginationPage}
                onPageChange={handleChangePage}
                rowsPerPage={paginationLimit}
                onRowsPerPageChange={handleChangeRowsPerPage}
                style={{ marginTop: 40 }}
              />
              <List>
              {rows.map((item, index) => (
                <>
                {index >= (paginationLimit * paginationPage) && index < ((paginationLimit * paginationPage)+paginationLimit) ? (
                  <ListItem>
                    <ListItemButton onClick={() => { openEdit(index); }}>
                      <ListItemAvatar>
                        <Avatar sx={{ width: 100, height: 100, marginRight: 2 }} src={item.image_front.length > 0 ? IMAGES_ENDPOINT+"products/"+item.image_front : ""} />
                      </ListItemAvatar>
                      <ListItemText primary={item.name} style={{ flex: 7, justifyContent: "flex-start" }} />
                      <ListItemText primary={item.brand} style={{ flex: 2, justifyContent: "flex-start" }} />
                      <ListItemIcon>
                          <div style={{ backgroundColor: item.essentials_status == 2 ? COLOR_INDICATOR3 : item.essentials_status == 1 ? COLOR_INDICATOR2 : COLOR_INDICATOR1, width: 5, height: 40, borderRadius: 5, right: 1, margin: 1 }} />
                          <div style={{ backgroundColor: item.details_status == 2 ? COLOR_INDICATOR3 : item.details_status == 1 ? COLOR_INDICATOR2 : COLOR_INDICATOR1, width: 5, height: 40, borderRadius: 5, right: 1, margin: 1 }} />
                          <div style={{ backgroundColor: item.productImages_status == 2 ? COLOR_INDICATOR3 : item.productImages_status == 1 ? COLOR_INDICATOR2 : COLOR_INDICATOR1, width: 5, height: 40, borderRadius: 5, right: 1, margin: 1 }} />
                          <div style={{ backgroundColor: item.characteristics_status == 2 ? COLOR_INDICATOR3 : item.characteristics_status == 1 ? COLOR_INDICATOR2 : COLOR_INDICATOR1, width: 5, height: 40, borderRadius: 5, right: 1, margin: 1 }} />
                          <div style={{ backgroundColor: item.packaging_status == 2 ? COLOR_INDICATOR3 : item.packaging_status == 1 ? COLOR_INDICATOR2 : COLOR_INDICATOR1, width: 5, height: 40, borderRadius: 5, right: 1, margin: 1 }} />
                          <div style={{ backgroundColor: item.ingredients_status == 2 ? COLOR_INDICATOR3 : item.ingredients_status == 1 ? COLOR_INDICATOR2 : COLOR_INDICATOR1, width: 5, height: 40, borderRadius: 5, right: 1, margin: 1 }} />
                          <div style={{ backgroundColor: item.certificates_status == 2 ? COLOR_INDICATOR3 : item.certificates_status == 1 ? COLOR_INDICATOR2 : COLOR_INDICATOR1, width: 5, height: 40, borderRadius: 5, right: 1, margin: 1 }} />
                      </ListItemIcon>
                    </ListItemButton>
                  </ListItem>
                ) : null}
                </>
              ))}
              </List>
              <TablePagination
                component="div"
                count={rows.length}
                page={paginationPage}
                onPageChange={handleChangePage}
                rowsPerPage={paginationLimit}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </div>
          )}
        </div>
      </div>
      <Modal
        open={isNew}
        style={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: 600, paddingTop: 40, paddingBottom: 60, height: "auto", backgroundColor: "#fff", flexDirection: "column" }}>
          <b style={{ fontSize: 23, marginBottom: 20 }}>Add a new product</b>
          {modalProductScreen === 0 && !modalProductLookup && (
            <>
              <TextField 
                id="standard-basic" 
                label="Barcode number" 
                variant="outlined" 
                className={classes.input_string} 
                onChange={(value) => {
                  setModalProductBarcode(value.target.value);
                }}
                value={modalProductBarcode}
              />
              <div style={{ flexDirection: "row", top: 20, position: "relative" }}>
                <Button size="medium" onClick={() => { closeModalNew(); }} style={{ color: COLOR_BRAND, backgroundColor: "#fff" }}>
                  CANCEL
                </Button>
                &nbsp;
                &nbsp;
                <Button style={{ color: "#fff", backgroundColor: COLOR_BRAND }} variant="contained" size="medium" onClick={() => { noBarcode(); }}>
                  NO BARCODE EXISTS
                </Button>
                &nbsp;
                &nbsp;
                <Button style={{ color: "#fff", backgroundColor: COLOR_BRAND }} variant="contained" size="medium" onClick={() => { saveBarcode(); }}>
                  SAVE BARCODE & CONTINUE
                </Button>
              </div>
            </>
          )}
          {modalProductScreen === 1 && !modalProductLookup && (
            <>
              {modalProductBarcode.length > 0 && (
                <TextField 
                  id="standard-basic" 
                  label="Barcode number" 
                  variant="outlined" 
                  disabled={true}
                  className={classes.input_string} 
                  style={{ marginBottom: 10 }}
                  onChange={(value) => {
                    setModalProductBarcode(value.target.value);
                  }}
                  value={modalProductBarcode}
                />
              )}
              <TextField 
                id="standard-basic" 
                label="Product name" 
                variant="outlined" 
                className={classes.input_string} 
                onChange={(value) => {
                  setModalProductLabel(value.target.value);
                }}
                value={modalProductLabel}
              />
              <div style={{ flexDirection: "row", top: 20, position: "relative" }}>
                <Button size="medium" onClick={() => { backBarcode(); }} style={{ color: COLOR_BRAND, backgroundColor: "#fff" }}>
                  BACK
                </Button>
                &nbsp;
                &nbsp;
                <Button style={{ color: "#fff", backgroundColor: COLOR_BRAND }} variant="contained" size="medium" onClick={() => { saveNew(); }}>
                  SAVE & FINISH
                </Button>
              </div>
            </>
          )}
          {modalProductLookup && (
            <Dots />
          )}
        </div>
      </Modal>
      <Modal
        open={isEdit}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        style={{ display: "flex", alignItems: "flex-start" }}
      >
        <div style={{ width: "100%", height: "100%", display: "flex", alignItems: "flex-start", justifyContent: "center", overflow: "auto" }}>
          <div style={{ position: "relative", width: "88%", width: 1200, minHeight: "94%", padding: "2%", paddingBottom: "5%", top: "1%", backgroundColor: "#fff", display: "flex", flexDirection: "column", borderRadius: 5 }}>
            <div style={{ position: "fixed", top: 10, right: 10, borderRadius: 20, width: 40, height: 40, cursor: "pointer", display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: "#ffffff" }} onClick={()=> {closeEdit(); }}>
              <Close />
            </div>
            <div style={{ display: "flex", width: "100%", flexDirection: "row" }}>
              <div style={{ display: "flex", flex: 1}}>
                <h2>{selectedIndex == -1 ? "New" : "Edit"} Product</h2>
              </div>
            </div>
            {isLoading ? <Dots /> : (
              <>
                <ProductGroup 
                  section={"essentials"}
                  id={idProduct}
                  isAdmin={isAdmin}
                  data={dataEssentials}
                />
                <ProductGroup 
                  section={"details"}
                  id={idProduct}
                  isAdmin={isAdmin}
                  data={dataEssentials}
                />
                <ProductGroup 
                  section={"productImages"}
                  id={idProduct}
                  isAdmin={isAdmin}
                  data={dataEssentials}
                />
                <ProductGroup 
                  section={"characteristics"}
                  id={idProduct}
                  isAdmin={isAdmin}
                  data={dataEssentials}
                />
                <ProductGroup 
                  section={"packaging"}
                  id={idProduct}
                  isAdmin={isAdmin}
                  data={dataEssentials}
                />
                <ProductGroup 
                  section={"ingredients"}
                  id={idProduct}
                  isAdmin={isAdmin}
                  data={dataEssentials}
                />
                <ProductGroup
                  section={"certificates"}
                  id={idProduct}
                  isAdmin={isAdmin}
                  data={dataEssentials}
                />
              </>
            )}
          </div>
        </div>
      </Modal>
      <Snackbar
        open={isSnackbar}
        autoHideDuration={1500}
        onClose={snackbarClose}
        message={snackbarMessage}
        action={<div />}
        key={Date.now()}
      />
    </>
  );
  
}