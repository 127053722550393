import React, {useEffect, useState} from "react";
import { useSelector } from 'react-redux';
import axios from "axios";
import Snackbar from '@mui/material/Snackbar';
import { makeStyles } from "@mui/styles";
import TextField from '@mui/material/TextField';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import { Cancel } from "@mui/icons-material";
import { Dots } from "react-activity";
import Typography from '@mui/material/Typography';
import { API_ENDPOINT, COLOR_BRAND } from "../../../consts";
import Status from "./Status";

const useStyles = makeStyles((theme) => ({
  container_outer: {
    display: "flex",
    width: "100%",
    backgroundColor: "#fafafa", 
    color: "#000", 
    borderBottom: "1px solid rgba(0,0,0,.2)",
    flexDirection: "column"
  },
  container_outer_save_button: {
    display: "flex",
    width: "96%", 
    padding: "2%",
    justifyContent: "center"
  },
  container_inner: {
    display: "flex",
    width: "96%", 
    padding: "2%",
    paddingBottom: "7%",
    color: "#000", 
    flexDirection: "row"
  },
  column_left: {
    flex: 1
  },
  column_center: {
    flex: 4
  }, 
  column_right: {
    flex: 1,
    flexDirection: "column",
    display: "flex"
  },
  input_string: {
    width: "80%",
    maxWidth: 750,
    marginBottom: 20,
    backgroundColor: "#ffffff"
  },
}));


export default function (props) {
  const classes = useStyles();
  const user = useSelector(state => state.user);
  const [isSnackbar, setIsSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isReadOnly, setIsReadOnly] = useState(true);
  const [data, setData] = useState(props.data);
  const [name, setName] = useState(props.data.name);
  const [ean, setEan] = useState(props.data.ean);
  const [synonyms, setSynonyms] = useState(props.data.synonyms);
  const [modalSynonym, setModalSynonym] = useState(false);
  const [modalSynonymText, setModalSynonymText] = useState("");
  

  const saveForm = () => {
    setIsReadOnly(true);
    setIsSaving(true);
    axios.post(API_ENDPOINT+"?func=update_product", {
      id_updatedBy: user.id,
      id: props.data.id,
      section: props.data.section,
      name: name,
      ean: ean,
      synonyms: JSON.stringify(synonyms)
    },{
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    })
    .then(res => {
      if(res.status === 200) {
        var tmpData = data;
        tmpData.essentials_status = 1;
        setData(tmpData);
        setSnackbarMessage("Product saved successfully");
        setIsSnackbar(true);
      } else {
        setIsReadOnly(false);
      }
      setIsSaving(false);
    });
  };

  const cancelForm = () => {
    setIsReadOnly(true);
    setName(props.data.name);
    setEan(props.data.ean);
    setSynonyms(props.data.synonyms);
  };

  const snackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setIsSnackbar(false);
  };
  
  return(
    <>
      <div className={classes.container_outer}>
        <div className={classes.container_inner}>
          <div className={classes.column_left}>
            <b>{props.label}</b>
          </div>
          <div className={classes.column_center}>
            <TextField 
              id="standard-basic" 
              label="Name" 
              InputProps={{
                readOnly: isReadOnly,
              }}
              style={{ backgroundColor: isReadOnly ? "#00000012" : "#fff" }}
              className={classes.input_string} 
              value={name}
              onChange={value => {
                setName(value.target.value);
              }}
            />
            <TextField 
              id="standard-basic" 
              label="EAN" 
              InputProps={{
                readOnly: isReadOnly,
              }}
              className={classes.input_string} 
              style={{ top: 20, backgroundColor: isReadOnly ? "#00000012" : "#fff" }}
              value={ean}
              onChange={value => {
                setEan(value.target.value);
              }}
            />
            <div style={{ top: 20, position: "relative" }}>
              <h5>Synonyms</h5><br />
              <Stack direction="row" spacing={1} className={classes.input_string} style={{ flexWrap: "wrap", backgroundColor: "transparent", marginTop: -30 }} >
                {synonyms.map((item, index) => (
                  <div>
                    <Chip 
                      label={<Typography style={{ userSelect: 'text' }}>{item.name}</Typography>}
                      onDelete={() => {
                        var tempData = [...synonyms];
                        for(var i = 0; i<tempData.length; i++) {
                          if(tempData[i].name === item.name) {
                            tempData.splice(i, 1);
                          }
                        }
                        setSynonyms(tempData);
                      }} 
                      deleteIcon={isReadOnly ? <></> : <Cancel />}
                      sx={{ justifyContent: "flex-start", marginBottom: 1 }}
                    />
                  </div>
                ))}
                {!isReadOnly ? (
                  <div 
                    onClick={() => { setModalSynonym(true); }}
                    style={{ width: 30, height: 30, backgroundColor: COLOR_BRAND+"99", borderRadius: 15, display: "flex", color: "#fff", fontWeight: "bold", justifyContent: "center", alignItems: "center", cursor: "pointer" }}
                  >
                    +
                  </div>
                ) : null}
              </Stack>
            </div>
          </div>
          <div className={classes.column_right}>
            <Status isSaving={isSaving} isReadOnly={isReadOnly} setIsReadOnly={setIsReadOnly} data={data} section={props.section} />
          </div>
        </div>
        {!isReadOnly ? (
          <div className={classes.container_outer_save_button}>
            <Button style={{ color: COLOR_BRAND, backgroundColor: "#fff", marginRight: 10 }} onClick={cancelForm} >Cancel</Button>
            <Button style={{ color: "#fff", backgroundColor: COLOR_BRAND }} variant="contained" size="medium" onClick={saveForm}>SAVE</Button>
          </div>
        ) : null}
      </div>
      <Modal
        open={modalSynonym}
        style={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: 600, height: 200, backgroundColor: "#fff", flexDirection: "column" }}>
          <TextField 
            id="standard-basic" 
            label="Add a synonym" 
            variant="outlined" 
            className={classes.input_string} 
            onChange={(value) => {
              setModalSynonymText(value.target.value);
            }}
            value={modalSynonymText}
          />
          <div style={{ flexDirection: "row", top: 20, position: "relative" }}>
            <Button size="medium" onClick={() => { setModalSynonym(false); }} style={{ color: COLOR_BRAND, backgroundColor: "#fff" }}>
              CANCEL
            </Button>
            &nbsp;
            &nbsp;
            <Button style={{ color: "#fff", backgroundColor: COLOR_BRAND }} variant="contained" size="medium" onClick={() => { 
              var tempSynonyms = [...synonyms];
              var newSynonym = {
                id: -1,
                name: modalSynonymText
              };
              tempSynonyms.push(newSynonym);
              setSynonyms(tempSynonyms); 
              setModalSynonymText("");
              setModalSynonym(false);
            }}>
              SAVE
            </Button>
          </div>
        </div>
      </Modal>
      <Snackbar
        open={isSnackbar}
        autoHideDuration={1000}
        onClose={snackbarClose}
        message={snackbarMessage}
        action={<div />}
        key={Date.now()}
      />
    </>
  );
}