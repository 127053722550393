import React, {useEffect, useState} from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Provider, useSelector, useDispatch } from 'react-redux';
import { makeStyles } from "@mui/styles";
import { NavBar } from "./components/NavBar";
import { SignIn } from "./components/SignIn";
import { Dashboard } from "./components/Dashboard";
import { Customers } from "./components/Customers";
import { CustomerProfile } from "./components/CustomerProfile";
import { PushNotifications } from "./components/PushNotifications";
import { PushNotificationsSend } from "./components/PushNotificationsSend";
import { Products } from "./components/Products/index";
import { ProductTypes } from "./components/ProductTypes";
import { Keywords } from "./components/Keywords";
import { Producers } from "./components/Producers";
import { Brands } from "./components/Brands";
import { Flavours } from "./components/Flavours";
import { Packaging } from "./components/Packaging";
import { Certificates } from "./components/Certificates";
import { Ingredients } from "./components/Ingredients";
import { Functions } from "./components/Functions";
import { SentencesH } from "./components/SentencesH";
import { SentencesG } from "./components/SentencesG";
import { SentencesHCategories } from "./components/SentencesHCategories";
import { SentencesGCategories } from "./components/SentencesGCategories";
import { Origins } from "./components/Origins";
import { TargetGroups } from "./components/TargetGroups";
import Store from './utilities/Store';

const useStyles = makeStyles((theme) => ({
  header: {
    display: "flex",
    width: "100%",
    height: 60,
    alignItems: "center",
    position: "fixed"
  },
  logo: {
    height: "80%",
    width: "auto",
    marginLeft: 20
  },
  app_name: {
    marginLeft: 20,
    color: "#fff",
    borderLeft: "1px solid #fff",
    paddingLeft: 15,
    fontWeight: "bold"
  },
  logout: {
    position: "absolute",
    right: 20,
    color: "#fff",
    cursor: "pointer"
  },
  background: {
    display: "flex",
    flex: 1,
    backgroundColor: "#00a64f"
  },
  root: {
    display: "flex",
    flex: 1,
  },
  content: {
    flex: 1,
    height: "100%",
    paddingLeft: 40,
    backgroundColor: "#f2f2f2",
    overflow: "auto"
  },
}));

function App() {
  const dispatch = useDispatch();
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const user = useSelector(state => state.user);
  const [lang, setLang] = useState("en");
  const classes = useStyles();
  const { innerWidth, innerHeight } = window;

  useEffect(() => {
    init();
  }, []);

  const init = async () => {
    const isSignedIn = localStorage.getItem('signedIn');
    if(isSignedIn){
      const userData = localStorage.getItem('userData');
      dispatch({ type: 'UPDATE_USER', payload: JSON.parse(userData) });
      setIsAuthenticated(true);
    }
  }

  const _setIsAuthenticated = async (bool) => {
    setIsAuthenticated(bool);
    localStorage.setItem('signedIn', bool);
  };

  const signOut = async () => {
    setIsAuthenticated(false);
    dispatch({ type: 'UPDATE_USER', payload: {id: null, name: null, is_admin: 0} });
    window.location.href = "/";
    localStorage.removeItem('signedIn');
    localStorage.removeItem('userData');
  }

  return (
      <div className={classes.background} style={{ height: "100vh" }}>
        <Router>
          {isAuthenticated ? (
            <div className={classes.root}>
              <NavBar signOut={signOut} />
              <div className={classes.content}>
                <Routes>
                  <Route path="" element={<Dashboard />} />
                  <Route path="products" element={<Products />} />
                  <Route path="product_types" element={<ProductTypes />} />
                  <Route path="keywords" element={<Keywords />} />
                  <Route path="target_groups" element={<TargetGroups />} />
                  <Route path="producers" element={<Producers />} />
                  <Route path="brands" element={<Brands />} />
                  <Route path="flavours" element={<Flavours />} />
                  <Route path="packaging_materials" element={<Packaging />} />
                  <Route path="certificates" element={<Certificates />} />
                  <Route path="ingredients" element={<Ingredients />} />
                  <Route path="functions" element={<Functions />} />
                  <Route path="sentences_h_categories" element={<SentencesHCategories />} />
                  <Route path="sentences_g_categories" element={<SentencesGCategories />} />
                  <Route path="sentences_h" element={<SentencesH />} />
                  <Route path="sentences_g" element={<SentencesG />} />
                  <Route path="origins" element={<Origins />} />
                  <Route path="customers" element={<Customers />} />
                  <Route path="customer_profile" element={<CustomerProfile />} />
                  <Route path="push_notifications" element={<PushNotifications />} />
                  <Route path="push_notifications_send" element={<PushNotificationsSend />} />
                </Routes>
              </div>
            </div>
          ) : (
            <Routes>
              <Route path="" element={<SignIn _setIsAuthenticated={_setIsAuthenticated} />} />
            </Routes>
          )}
        </Router>
      </div>
  );
}

export default App;